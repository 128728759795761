<template>
  <div class="goods-pull-refresh">
    <template v-if="goodsList && goodsList.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :loading-text="loadingText"
          :finished-text="finishedText"
          @load="getDataList"
        >
          <product-list :data="goodsList" />
        </van-list>
      </van-pull-refresh>
    </template>
    <template v-else>
      <div class="no-list">
        <div class="no-img">
          <img src="../assets/images/zwsj.png" alt="" />
        </div>
        <div class="no-tip">抱歉，当前搜索项暂无商品</div>
      </div>
    </template>
  </div>
</template>

<script>
import { List, PullRefresh } from "vant";
import { digitalproductpage } from "@/api/common";
import ProductList from "./ProductList.vue";
import { mapState } from "vuex";
export default {
  name: "DigitalGoods",
  props: {
    bgColor: {
      default: "#fff",
    },
    showFaceValue: {
      default: false,
    },
    tagName: {
      default: "",
    },
  },
  components: {
    ProductList,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: true,
      loadingText: "",
      finishedText: "我也是有底线的~",
      goodsList: [],
      cateId: "",
    };
  },
  computed: {
    ...mapState(["poductName"]),
  },
  created() {},
  methods: {
    // 获取商品列表
    async getDataList(CateId, type) {
      console.log(this.poductName);
      this.cateId = CateId || "";
      if (this.cateId) {
        this.pageIndex = 1;
        this.pageSize = 10;
        this.goodsList = [];
      }
      const res = await digitalproductpage({
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        CateId: this.cateId == "all" ? "" : this.cateId,
        ProductName: this.poductName,
      });
      if (res && res.code == 0) {
        if (res.data.list.length > 0) {
          this.pageIndex = this.pageIndex + 1;
        }

        if (type == "down") {
          this.goodsList = res.data?.list || [];
          this.refreshing = false;
          return;
        }

        this.goodsList = [...this.goodsList, ...(res.data?.list || [])];
        this.total = res.data.total;
        this.loading = false;
        this.$nextTick(() => {
          console.log(this.goodsList);
          if (this.goodsList.length >= res.data.total) {
            this.finished = true;
          }
        });
      } else {
        this.$toast(res.message);
      }
    },

    // 下拉刷新
    onRefresh() {
      this.pageSize = 10;
      this.pageIndex = 1;
      this.finished = false;
      this.$nextTick(() => {
        this.getDataList(this.cateId, "down");
      });
    },

    // 点击商品
    clickGoods(item) {
      console.log(item);
      this.$router.push(`/mall/ProductDetails?groupId=${item.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.goods-pull-refresh {
  height: 100%;
  /deep/.van-pull-refresh {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 170px);
  }
  .no-list {
    width: 100%;
    height: 100%;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-img {
      width: 1.96rem;
      height: 1.96rem;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ccc;
      margin-top: 30%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .no-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.32rem;
    }
  }
}
</style>