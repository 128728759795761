<template>
  <div class="digital-con">
    <div
      class="product-item-digital"
      v-for="(item, index) in data"
      :key="index"
      @click="clickGoods(item)"
    >
      <div class="digital-img-box">
        <img :src="item.productImg || defaultProductImg" alt="" />
      </div>
      <div class="digital-content">
        <div class="goods-name">
          <span v-if="item.isTag" class="goods-tag">立减</span
          >{{ item.productName }}
        </div>
        <div class="goods-price">
          <div class="price-left">
            <span class="unit">￥</span>
            <span class="price"
              >{{ mathManage.formatMoney(item.productMinSalePrice, 2) }}
            </span>
            <span class="unit" style="margin-left:0.05rem"> 起</span>
          </div>
          <span class="product-type">{{ productType[item.deliveryMode] }}</span>
          <!-- <span class="tip">劵后价</span> -->
          <!-- <s class="del">￥{{ item.ProductCost }}</s> -->
        </div>
        <!-- <div v-if="showFaceValue" class="face-value">
            市场价
            <span class="value">￥{{ item.faceValue }}</span>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import mathManage from "@/utils/mathManage";
import { productType } from "@/utils/dictionary";
import { mapState } from "vuex";
export default {
  name: "ProductList",
  props: {
    data: {
      default: () => [],
    },
  },
  data() {
    return {
      productType,
      mathManage,
    };
  },
  computed: {
    ...mapState(["defaultProductImg"]),
  },
  methods: {
    // 点击商品
    clickGoods(item) {
      this.$router.push(`/mall/ProductDetails?groupId=${item.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.digital-con {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .product-item-digital {
    float: left;
    width: 49%;
    min-height: 3.52rem;
    border-radius: 0.12rem;
    overflow: hidden;
    margin-bottom: 0.15rem;

    .digital-img-box {
      height: 2.2rem;
      overflow: hidden;
      padding: 0.14rem;

      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0.12rem;
      }
    }
    .digital-content {
      padding: 0 0.16rem;
      padding-top: 0 !important;
      min-height: 1.5rem;
      overflow: hidden;
      background-color: #fff;
      .goods-name {
        height: 0.78rem;
        font-size: 0.26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        white-space: pre-wrap;
        display: -webkit-box;
        /* 弹性盒旧式语法 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        /* 限制两行 */
        overflow: hidden;

        .goods-tag {
          display: inline-block;
          height: 0.32rem;
          background: linear-gradient(117deg, #ff7d58 0%, #ff503f 100%);
          border-radius: 0.04rem;
          padding: 0 0.06rem;
          font-size: 0.2rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 0.34rem;
          margin-right: 0.08rem;
          vertical-align: middle;
        }
      }
      .goods-price {
        margin-top: 0.15rem;
        // margin-bottom: 0.05rem;
        height: 0.36rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price-left {
          display: flex;
          align-items: inherit;
          justify-content: flex-start;
        }

        .unit {
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #ff503f;
          transform: translateY(0.04rem);
        }
        .price {
          font-size: 0.36rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #ff503f;
        }

        .product-type {
          font-size: 0.19rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          background: #f9f9f9;
          padding: 0.03rem 0.12rem;
        }

        .tip {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: normal;
          color: #ff503f;
          margin-left: 0.04;
          padding-top: 0.02rem;
          margin-right: 0.3rem;
        }

        .del {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b1b1b1;
        }
      }

      .face-value {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b1b1b1;
        display: flex;
        align-items: center;
        margin-top: 0.12rem;
      }
    }
  }
}
</style>
